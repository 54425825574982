<template>
  <Modal
    :size="modalProps.size"
    :buttonOpen="buttonOpen"
    :resetFunction="resetModal"
  >
    <SelectProfileSignUp
      :modalProps="modalProps"
      @update-modal-props="updateModalProps"
      :userData="userData"
      @update-user-data="handleUpdateUserData"
      v-if="modalProps.step === 'selectProfile'"
    />
    <UseTerms
      :modalProps="modalProps"
      @update-modal-props="updateModalProps"
      :userData="userData"
      @update-user-data="handleUpdateUserData"
      v-else-if="modalProps.step === 'useTerms'"
    />
    <RegisterData
      :modalProps="modalProps"
      :userData="userData"
      :successErrorModal="successErrorModal"
      @update-success-error-modal="handleUpdateSuccessErrorModal"
      v-else-if="modalProps.step === 'registerData'"
    />
    <ModalSuccessError
      :modalProps="modalProps"
      :successErrorModal="successErrorModal"
      :userData="userData"
      @update-user-data="handleUpdateUserData"
      @update-modal-props="updateModalProps"
      v-else-if="modalProps.step === 'successError'"
    />
    <div v-else>Step not found {{ userData.visao_usuario }}</div>
  </Modal>
</template>

<script>
import Modal from "@/components/signupModal/Modal.vue";
import SelectProfileSignUp from "@/components/signupModal/SelectProfileSignUp.vue";
import UseTerms from "@/components/signupModal/UseTerms.vue";
import ModalSuccessError from "@/components/signupModal/ModalSuccessError.vue";
import SignUpButtonVue from "@/components/signupModal/SignUpButton.vue";
import RegisterData from "@/components/signupModal/RegisterData.vue";

export default {
  name: "SignUpModal",
  components: {
    SelectProfileSignUp,
    Modal,
    UseTerms,
    RegisterData,
    ModalSuccessError,
  },
  data() {
    return {
      buttonOpen: SignUpButtonVue,
      modalProps: {
        step: "selectProfile",
        size: "lg",
      },
      userData: {
        visao_usuario: "",
        nome: "",
        email: "",
        cargo: "",
        cnpj: "",
        razao_social: "",
        aceitar_termos: true,
      },
      successErrorModal: {
        title: "",
        message: "",
        buttonLabel: "",
        isSuccess: true,
      },
    };
  },
  methods: {
    handleUpdateUserData(newUserData) {
      this.userData = newUserData;
    },
    updateModalProps(newModalProps) {
      this.modalProps = newModalProps;
    },
    handleUpdateSuccessErrorModal(newSuccessErrorModal) {
      this.successErrorModal = newSuccessErrorModal;
    },
    resetModal() {
      this.modalProps = {
        step: "selectProfile",
        size: "lg",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="w-100">
    <b-button
      v-b-modal.modal-forgot-password
      class="modal-button-open form-group container-fluid form-check d-flex mb-5 ml-auto bg-transparent border-0 shadow-none"
    >
      <component :is="buttonOpen" />
    </b-button>

    <b-modal
      name="modal-forgot-password"
      id="modal-forgot-password"
      hide-footer
      hide-header
      centered
      size="xs"
      @hidden="onModalHidden"
    >
      <div class="header-modal-area">
        <button class="close" @click="$bvModal.hide('modal-forgot-password')">
          <span class="close-button" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        v-if="
          requestButtonStatus === 'loading' || requestButtonStatus === 'idle'
        "
      >
        <div class="modal-content">
          <h1 class="title">Esqueci minha senha</h1>
          <p class="message">
            Por favor, informe o seu e-mail registrado no Vitrine para receber
            um link de redefinição da senha.
          </p>
          <form @submit.prevent="handleSendRequest">
            <Input
              :id="'email'"
              :name="'email'"
              :placeholder="'E-mail'"
              :required-message="emailErrorMessage"
              v-model="email"
              @input="emailErrorMessage = ''"
              required
            />
            <Button
              :type="'submit'"
              :label="
                requestButtonStatus === 'idle' ? 'Enviar link' : 'Enviando'
              "
              :disabled="
                requestButtonStatus === 'loading' ||
                requestButtonStatus === 'success' ||
                requestButtonStatus === 'error'
              "
            ></Button>
          </form>
        </div>
      </div>
      <div
        v-else-if="requestButtonStatus === 'success'"
        class="content-success-error-area"
      >
        <div class="icon success-icon">
          <img src="../../assets/icon-check.svg" alt="Success" />
        </div>
        <h1 class="title">Link enviado</h1>
        <p class="message">Acesse seu e-mail e clique no link enviado.</p>
      </div>
      <div
        v-else-if="requestButtonStatus === 'error'"
        class="content-success-error-area"
      >
        <div class="icon error-icon">
          <img src="../../assets/icon-error.svg" alt="Error" />
        </div>
        <h1 class="title">Erro ao enviar e-mail</h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase";

import Validators from "@/utils/Validators";

import ForgotPasswordButton from "@/components/forgotPasswordModal/ForgotPasswordButton.vue";
import Input from "@/components/ui/Input.vue";
import Button from "@/components/ui/Button.vue";

export default {
  name: "ForgotPasswordModal",
  components: { Input, Button },
  data() {
    return {
      buttonOpen: ForgotPasswordButton,
      requestButtonStatus: "idle",
      email: "",
      emailErrorMessage: "",
    };
  },
  methods: {
    handleSendRequest() {
      if (!this.email) {
        this.emailErrorMessage = "Campo obrigatório";
        return;
      }

      if (!Validators.validateEmail(this.email)) {
        this.emailErrorMessage = "E-mail inválido";
        return;
      }

      this.requestButtonStatus = "loading";

      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.requestButtonStatus = "success";
        })
        .catch((error) => {
          this.requestButtonStatus = "error";
          console.error(error);
        });
    },
    onModalHidden() {
      this.requestButtonStatus = "idle";
      this.email = "";
      this.emailErrorMessage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  font-size: 32px;
  color: $neutral-95;
  font-weight: 400;
}

.modal-content {
  padding: 26px 80px;
  border: 0px !important;

  > form {
    > p {
      margin-top: 8px;
    }

    > button {
      margin-top: 65px;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.message {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-80;
}

.content-success-error-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;

  padding: 56px 44px;
}

.icon {
  width: 80px;
  height: 80px;
  flex-grow: 0;
  padding: 16px;
  border-radius: 100px;

  animation: 1s jump ease-in-out;

  @keyframes jump {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}

.success-icon {
  background-color: $background-success-message;
}

.error-icon {
  background-color: $background-error-message;
}
</style>

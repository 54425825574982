<template>
  <div class="login-wrapper">
    <div class="card login-form">
      <div class="card-body py-5 px-4">
        <div class="logo mb-4">
          <div class="d-flex justify-content-center mb-4">
            <div>
              <img class="ml-2" src="@/assets/logo.svg" alt="logo-vitrine" />
            </div>
          </div>
        </div>
        <form methods="POST" @submit="checkForm">
          <b-alert :show="!!errors.length" dismissible variant="warning" @dismissed="dismissCountDown = 0"
            @dismiss-count-down="10">
            <p>Verificar o(s) seguinte(s) campo(s)</p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </b-alert>

          <div class="form-group">
            <label for="email">E-mail</label>
            <input id="email" type="email" class="form-control" placeholder="E-mail de acesso" v-model="form.email"
              aria-describedby="emailHelp" />
          </div>
          <div class="form-group">
            <label for="password">Senha</label>
            <input id="password" type="password" placeholder="Sua senha" v-model="form.password" class="form-control" />
          </div>
          <ForgotPasswordModal />
          <button v-show="!doLoging" type="submit" class="btn btn-primary btn-block mb-3">
            Entrar
          </button>
          <b-button v-show="doLoging" class="btn btn-primary btn-block mb-3" disabled>
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </b-button>
          <div class="d-flex mb-3 justify-content-center container-fluid">
            <ModalSignup />
          </div>
        </form>

        <!-- <router-link
          class="btn btn-block"
          to="/auth/signup"
        >
        Cadastre-se
        </router-link> -->
      </div>
    </div>
  </div>
</template>

.
<style lang="scss" scoped>
input {
  width: 100%;
  height: 40px;
  border: 1px solid #929a9c;
  box-sizing: border-box;
  border-radius: 5px;
}

small {
  margin-right: 4px;
}

.login-wrapper {
  display: flex;
  height: 80vh;
  width: 100vw;

  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 4%;
  }
}

.login-form {
  width: 100%;
  margin: auto;
}

.card {
  width: 420px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 428px;
}

.logo {
  >img {
    width: 100%;
  }

  >p {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    color: #001b74;
    text-align: center;
  }
}
</style>

<script>
import firebase from "firebase";
import ModalSignup from "@/components/signupModal/index.vue";
import ForgotPasswordModal from "@/components/forgotPasswordModal/index.vue";
import axios from "axios";

export default {
  components: {
    ModalSignup,
    ForgotPasswordModal,
  },
  data() {
    return {
      errors: [],
      form: {},
      doLoging: false,
      client: {},
      master: {},
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.doLoging = true;

      if (this.form.email && this.form.password) {
        // return true;
      }

      this.errors = [];

      if (!this.form.email) {
        this.errors.push("Campo E-mail é obrigatório.");
        this.doLoging = false;
      }

      if (!this.form.password) {
        this.errors.push("Campo Senha é obrigatório.");
        this.doLoging = false;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(async (data) => {
          this.$analytics.setUserProperties({ email: this.form.email });
          this.$analytics.setUserId(this.form.email);
          this.$analytics.logEvent("login", {
            email: this.form.email,
          });

          this.$session.start();
          this.$session.set("email", this.form.email);
          this.$session.set("userId", data.user.uid);
          this.$session.set(
            "token",
            await firebase.auth().currentUser.getIdToken()
          );

          await axios
            .get("/api/clients/vitrine_clientes/" + data.user.uid)
            .then(async (response) => {

              this.client = response.data;
              this.$session.set("client", this.client);

              // If there is no id_cliente, the registering was not completed until step two
              // So redirect it to step one
              if (this.client.id_cliente === "") {
                  this.$router.push("/signup");
              }
              else {

                if (this.client.user_master) {

                  this.master = this.client;
                  this.$session.set("master", this.master);

                } else {

                  await axios
                    .get("/api/clients/vitrine_clientes/" + this.client.uid_master)
                    .then((response) => {
                      this.master = response.data;
                      this.$session.set("master", response.data);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }

                // Redirect to start page
                this.client.visao_usuario === "incorporadora" ? this.$router.push("/enterprises") : this.$router.push("/search");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.$analytics.logEvent("login", {
            event_category: "login",
            event_label: "error",
            value: error.message,
          });

          this.errors.push(error.message);
          this.doLoging = false;
        });
    }
  },
};
</script>

<template>
  <div class="container">
    <div class="header-area">
      <button @click="handleGoBackSelectStep()">
        <ArrowLeftIcon />
      </button>
      <h2 v-if="updatedUserData.visao_usuario === 'fundo_investimento'">
        Sou Agente Financeiro
      </h2>
      <h2 v-if="updatedUserData.visao_usuario === 'incorporadora'">
        Sou Incorporadora
      </h2>
    </div>
    <div
      @scroll="verifyScrollEnd"
      class="content-terms"
      v-html="useTermsMock"
    ></div>
    <b-button
      :disabled="disableButton"
      class="w-100"
      variant="primary"
      @click="handleAcceptTerms"
      >Aceitar</b-button
    >
  </div>
</template>

<script>
import { ArrowLeftIcon } from "vue-feather-icons";
import Button from "@/components/ui/Button.vue";

// const useTermsMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
//   .map((item) => {
//     return `
// <h3><strong>Lorem Ipsum Title ${item}</strong></h3>
// <p>
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget ligula eu lectus lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla at risus. Quisque purus magna, auctor et, sagittis ac, posuere eu, lectus. Nam mattis, felis ut adipiscing
// </p>
// <br />
// `;
//   })
//   .join("");

const useTermsMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  .map((item) => {
    return `
    `;
  })
  .join("");

export default {
  name: "UseTerms",
  components: {
    ArrowLeftIcon,
    Button,
  },
  props: {
    userData: Object,
    modalProps: Object,
  },
  data() {
    return {
      useTermsMock,
      updatedUserData: this.userData,
      updateModalProps: this.modalProps,
      disableButton: false,
      labelButton: "Aceitar",
    };
  },
  methods: {
    handleGoBackSelectStep() {
      this.updateModalProps.step = "selectProfile";
      this.updatedUserData.visao_usuario = "";
      this.$emit("update-modal-props", this.updateModalProps);
      this.$emit("update-user-data", this.updatedUserData);
    },
    handleAcceptTerms() {
      this.updatedUserData.aceitar_termos = true;
      this.$emit("update-user-data", this.updatedUserData);
      this.handleUpdateModalProps();
    },
    verifyScrollEnd({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.disableButton = false;
      }
    },
    handleUpdateModalProps() {
      this.updateModalProps.step = "registerData";
      this.updateModalProps.size = "xs";
      this.$emit("update-step-show", this.updatedStepShow);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 24px;
  padding: 0px 24px 32px 24px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: $neutral-95;
  }
}

.header-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  > button {
    background: $white;
    border: none;
    cursor: pointer;

    padding: 4px;

    border-radius: 100%;

    transition: all 0.3s ease-out;

    &:hover {
      filter: brightness(0.85);
    }
  }

  > h2 {
    color: $neutral-95;
    font-size: 22px;
    font-weight: bold;

    margin-bottom: 0;

    vertical-align: middle;
  }
}

.content-terms {
  overflow-y: auto;
  height: 60vh;
  padding-right: 18px;

  h3,
  strong {
    font-size: 18px;
    font-weight: 600;
    color: $neutral-95;
  }

  > p {
    font-size: 16px;
    color: $neutral-95;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $blue-light;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $main-blue;
  }
}
</style>

<template>
  <span
    class="forgot-password-button"
  >
    Esqueci a senha
  </span>
</template>

<script>
export default {
  name: "ForgotPasswordButton",
};
</script>

<style lang="scss" scoped>
.forgot-password-button {
  width: 100%;

  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;

  display: flex;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}
</style>

<template>
  <div class="container-fluid p-0 m-0">
    <div class="container-fluid p-0 m-0" v-if="successErrorModal.isSuccess">
      <div
        class="d-flex flex-column m-3 pb-3 align-items-center justify-content-center"
      >
        <img src="@/assets/success-modal-icon.svg" alt="Sucesso" />
      </div>
      <div
        class="d-flex flex-column px-3 my-1 align-items-center justify-content-center"
      >
        <h3 class="title-success-error">{{ successErrorModal.title }}</h3>
        <p class="message-success-error">{{ successErrorModal.message }}</p>
      </div>
      <div
        class="d-flex flex-column px-5 mt-2 mb-4 w-100 align-items-center justify-content-center"
      >
        <b-button class="mt-3 w-100" variant="primary" @click="closeModal">{{
          successErrorModal.buttonLabel
        }}</b-button>
      </div>
    </div>
    <div v-else class="container-fluid p-0 m-0">
      <div
        class="d-flex flex-column px-3 my-1 align-items-start justify-content-start"
      >
        <h3 class="title-success-error">{{ successErrorModal.title }}</h3>
        <p class="message-success-error">{{ successErrorModal.message }}</p>
      </div>
      <div
        class="d-flex flex-column px-5 mt-2 mb-4 w-100 align-items-center justify-content-center"
      >
        <b-button class="mt-3 w-100" variant="primary" @click="closeModal">{{
          successErrorModal.buttonLabel
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nome: "ModalSuccessError",
  props: {
    successErrorModal: Object,
    userData: Object,
    modalProps: Object,
  },
  data() {
    return {
      updatedUserData: this.userData,
      updateModalProps: this.modalProps,
    };
  },
  methods: {
    closeModal() {
      if (this.successErrorModal.isSuccess) {
        this.$router.push({
          name: "signup",
          params: {
            updatedUserData: this.userData,
          },
        });
      } else {
        this.$bvModal.hide("modal");
        this.$nextTick(() => {
          this.updatedUserData = {
            visao_usuario: "",
            nome: "",
            email: "",
            cargo: "",
            cnpj: "",
            razao_social: "",
            aceitar_termos: true,
          };
          this.$emit("update-user-data", this.updatedUserData);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-success-error {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.message-success-error {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}
</style>

<template>
  <div class="container">
    <h1>Para começar,</h1>
    <div class="buttons-area">
      <button @click="handleSetCompanyType('incorporadora')">
        <div class="icon incorporated"></div>
        <span>Sou Incorporadora</span>
      </button>
      <button @click="handleSetCompanyType('fundo_investimento')">
        <div class="icon found"></div>
        <span>Sou Agente Financeiro</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectProfileSignUp",
  props: {
    userData: Object,
    modalProps: Object,
  },
  data() {
    return {
      updatedUserData: this.userData,
      updateModalProps: this.modalProps,
    };
  },
  methods: {
    handleSetCompanyType(companyType) {
      this.updatedUserData.visao_usuario = companyType;
      this.$emit("update-user-data", this.updatedUserData);
      this.updateStep();
    },
    updateStep() {
      this.updateModalProps.step = "useTerms";
      this.$emit("update-modal-props", this.updateModalProps);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 24px;
  padding: 0px 24px 32px 24px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: $neutral-95;
  }
}

.buttons-area {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;

  button {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 32px 24px;
    gap: 24px;

    border: 1px solid $neutral-40;
    border-radius: 10px;

    background: none;

    transition: all 0.3s ease-in-out;

    span {
      font-size: 20px;
      font-weight: 700;
      color: $neutral-95;
    }

    .icon {
      width: 100px;
      height: 100px;
      background-color: $neutral-40;

      transition: all 0.3s ease-in-out;
    }

    .incorporated {
      -webkit-mask: url(../../assets/incorporation-icon.svg) no-repeat center;
      mask: url(../../assets/incorporation-icon.svg) no-repeat center;
    }

    .found {
      -webkit-mask: url(../../assets/investment-found.svg) no-repeat center;
      mask: url(../../assets/investment-found.svg) no-repeat center;
    }

    &:hover {
      box-shadow: 0 8px 24px 0 $shadow-color;
      background: $background-select-primary;
      border: 1px solid $orange;

      .incorporated,
      .found {
        background-color: $orange;
      }

      .icon {
        transform: scale(1.1);
      }
    }
  }
}
</style>

<template>
  <div>
    <b-button v-b-modal.modal class="modal-button-open">
      <component :is="buttonOpen" />
    </b-button>

    <b-modal
      name="modal"
      id="modal"
      hide-footer
      hide-header
      centered
      :size="size"
      @hidden="resetFunction"
    >
      <div class="header-modal-area">
        <button class="close" @click="$bvModal.hide('modal')">
          <span class="close-button" aria-hidden="true">&times;</span>
        </button>
      </div>
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    buttonOpen: {
      type: Object,
    },
    size: {
      type: String,
      default: "lg",
    },
    resetFunction: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-button-open {
  background: none;
  border: none;

  cursor: pointer;

  padding: 0;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.header-modal-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;

  .close {
    background: none;
    border: none;
    cursor: pointer;
  }
  .close-button {
    font-size: 28px;
    font-weight: normal;
  }
}
</style>

<template>
  <p class="button-style">
    Não possui conta? <span class="sign-up-bold">Cadastre-se</span>
  </p>
</template>

<script>
export default {
  name: "SignUpButton",
};
</script>

<style lang="scss" scoped>
.button-style {
  background: none;
  border: none;

  font-size: 16px;
  color: $neutral-95;

  margin: 0;
  padding: 0;
}

.sign-up-bold {
  color: $blue-light;

  font-weight: bold;
}
</style>
